import tw from 'twin.macro';

type Props = {
  title: string;
};

const Title = ({ title }: Props) => (
  <div>
    <h1 css={tw`text-center font-bold text-2xl md:text-4xl`}>{title}</h1>
  </div>
);

export default Title;
