import * as React from 'react';
import tw from 'twin.macro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import Title from '../components/title';
import ContentWidth from '../components/contentWidth';

type Props = {
  location: Location;
};

function Contact({ location }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.contact.path}
    >
      <SEO
        image={seoData.contact.image?.[defaultLangKey]}
        title={seoData.contact.title?.[defaultLangKey]}
        description={seoData.contact.description?.[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <section
        css={tw`flex flex-col justify-center relative`}
        style={{ minHeight: '100vh' }}
      >
        <ContentWidth>
          <div css={tw`mt-24 md:mt-12`}>
            <Title title={`お問い合わせ`} />
          </div>
          <section css={tw`mt-12 max-w-screen-md mx-auto`}>
            <form
              name="contact"
              css={tw`w-full`}
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              action="/"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <div css={tw`flex flex-wrap -mx-3 mb-2`}>
                <div css={tw`w-full px-3`}>
                  <label
                    css={tw`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
                    htmlFor="address"
                  >
                    件名(<span css={tw`text-red-400`}>*</span>)
                  </label>
                  <input
                    css={tw`appearance-none block w-full bg-gray-200 text-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-opacity-10 focus:bg-opacity-10`}
                    name="subject"
                    type="text"
                    required={true}
                    placeholder="件名を入力してください"
                  />
                </div>
              </div>
              <div css={tw`flex flex-wrap -mx-3 mb-2`}>
                <div css={tw`w-full md:w-1/2 px-3`}>
                  <label
                    css={tw`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
                    htmlFor="last-name"
                  >
                    姓(<span css={tw`text-red-400`}>*</span>)
                  </label>
                  <input
                    css={tw`appearance-none block w-full bg-gray-200 text-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-opacity-10 focus:bg-opacity-10`}
                    id="last-name"
                    type="text"
                    required={true}
                    name="last-name"
                    placeholder="山田"
                  />
                </div>
                <div css={tw`w-full md:w-1/2 px-3`}>
                  <label
                    css={tw`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
                    htmlFor="first-name"
                  >
                    名(<span css={tw`text-red-400`}>*</span>)
                  </label>
                  <input
                    css={tw`appearance-none block w-full bg-gray-200 text-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-opacity-10 focus:bg-opacity-10`}
                    id="first-name"
                    type="text"
                    required={true}
                    name="first-name"
                    placeholder="太郎"
                  />
                </div>
              </div>
              <div css={tw`flex flex-wrap -mx-3 mb-2`}>
                <div css={tw`w-full px-3`}>
                  <label
                    css={tw`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
                    htmlFor="tel"
                  >
                    TEL(任意)
                  </label>
                  <input
                    css={tw`appearance-none block w-full bg-gray-200 text-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-opacity-10 focus:bg-opacity-10`}
                    id="grid-address"
                    type="tel"
                    name="tel"
                    placeholder="000-0000-0000"
                  />
                </div>
              </div>
              <div css={tw`flex flex-wrap -mx-3 mb-2`}>
                <div css={tw`w-full px-3`}>
                  <label
                    css={tw`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
                    htmlFor="email"
                  >
                    メールアドレス(<span css={tw`text-red-400`}>*</span>)
                  </label>
                  <input
                    css={tw`appearance-none block w-full bg-gray-200 text-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-opacity-10 focus:bg-opacity-10`}
                    id="grid-address"
                    required={true}
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                  />
                </div>
              </div>
              <div css={tw`flex flex-wrap -mx-3 mb-2`}>
                <div css={tw`w-full px-3`}>
                  <label
                    css={tw`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
                    htmlFor="message"
                  >
                    お問い合わせ内容(<span css={tw`text-red-400`}>*</span>)
                  </label>
                  <textarea
                    css={tw`appearance-none block w-full bg-gray-200 text-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 bg-opacity-10 focus:bg-opacity-10`}
                    id="message"
                    name="message"
                    placeholder="お問い合わせ内容を入力してください"
                  />
                </div>
              </div>

              <div css={tw`flex flex-wrap -mx-3 mb-2`}>
                <div css={tw`w-full px-3`}>
                  <button
                    type="submit"
                    css={tw`py-5 px-12 rounded bg-white bg-opacity-10 border border-white text-white active:text-gray-900 focus:text-gray-900 hover:text-gray-900 text-sm font-bold focus:bg-white active:bg-white hover:bg-white`}
                  >
                    送信する
                  </button>
                </div>
              </div>
            </form>
          </section>
        </ContentWidth>
      </section>
    </Layout>
  );
}

export default Contact;
